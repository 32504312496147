/* App ..................................................................... */

main {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: 0 auto;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: space-between;
}

main header {
	text-align: center;
	color: var(--logo-text-color);
}
main header::after {
	content: '|||';
	display: inline-block;
	letter-spacing: -0.6rem;
	transform: translate(0.1rem, -0.4rem) rotate(75deg);
}
