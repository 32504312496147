/* Styles .................................................................. */

/* Create React App's webpack config will hoist all CSS @imports */
@import './vars.css';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Press Start 2P', monospace;
}

html,
body {
	font-size: 4.5vw;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	touch-action: manipulation;
}
html {
	background: var(--noise),
		linear-gradient(
			90deg,
			rgba(0, 0, 0, 0),
			rgba(0, 0, 0, 0.3),
			rgba(0, 0, 0, 0)
		);
	background-color: var(--bg-color);
}

/* If the aspect ratio is not tall enough, everything should be centered */
@media (min-aspect-ratio: 860/962) {
	html,
	body {
		font-size: 4vh;
	}
	main {
		width: 21rem;
	}
}
