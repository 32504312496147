/* Key ..................................................................... */

.key,
.key::after {
	/* Make the buttons round */
	border-radius: 50% 50%;
	overflow: hidden;
	transition: transform 0.05s, box-shadow 0.05s, background 0.05s;
}
.key {
	position: relative;
	margin: 0.5rem;
	height: 2.5rem;
	/* Add some super ugly skeumorphism */
	box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.5);
	background: var(--noise),
		radial-gradient(
			ellipse at top,
			var(--key-highlight-color),
			var(--key-bg-color)
		);
	background-color: var(--key-bg-color);
	color: var(--key-text-color);
	border-width: 0;
}
.key:focus {
	/* This outline ruins the retro immersion */
	outline: 0;
}
.key::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	/* Continue adding that super ugly skeumorphism */
	box-shadow: inset -0.1rem -0.1rem 1rem 0.1rem rgba(255, 255, 255, 0.2);
}
.key:active,
.key:active::after {
	/* Fake depression of button */
	transform: translate(0.1rem, 0.1rem);
}
.key:active {
	/* Fake depression of button */
	box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.5);
}
.key:active::after {
	/* Fake depression of button */
	box-shadow: inset -0.5rem -0.5rem 1rem 0.1rem rgba(255, 255, 255, 0.2);
}

.key figure {
	display: flex;
	padding: 0 1.1rem;
	/* It didn't look ugly enough without italic! */
	font-style: italic;
}

.key p,
.key figcaption {
	line-height: 2.433rem;
	vertical-align: middle;
}

.key p {
	flex: auto;
	font-size: 1.4rem;
	text-align: left;
}
.key p:last-child {
	text-align: center;
}
.key p + figcaption {
	flex: 45%;
	display: block;
	text-transform: uppercase;
	text-align: left;
	font-size: 0.5rem;
	padding-left: 0.3rem;
}
