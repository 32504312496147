/* Screen .................................................................. */

.screen {
	position: relative;
	flex: 1;
	padding: 1rem;
	margin-top: 1rem;
	font-size: 1.5rem;
	line-height: 2rem;
	overflow-wrap: break-word;
	word-break: break-word;
	hyphens: none;
	overflow: hidden;
	color: var(--screen-text-color);
	/* Add some super ugly skeumorphism */
	box-shadow: inset 0rem -1rem 10rem -1rem white,
		0 0 0.3rem 0.3rem rgba(0, 0, 0, 0.2);
	background-color: var(--screen-bg-color);
	pointer-events: none;
}

.screen::after {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	/* Add even more ugly skeumorphism */
	background: var(--noise),
		repeating-linear-gradient(
			to right,
			var(--screen-bg-color) 0 var(--pixel-gap),
			transparent calc(2 * var(--pixel-gap))
				calc(2 * var(--pixel-gap) + var(--pixel-width))
		),
		repeating-linear-gradient(
			to bottom,
			var(--screen-bg-color) 0 var(--pixel-gap),
			transparent calc(2 * var(--pixel-gap))
				calc(2 * var(--pixel-gap) + var(--pixel-width))
		),
		linear-gradient(
			90deg,
			rgba(255, 0, 0, 0.04),
			rgba(0, 255, 0, 0.2),
			rgba(0, 0, 255, 0.08)
		);
	box-shadow: inset -0.1rem -0.1rem 0.5rem 0.1rem rgba(0, 0, 0, 0.5);
}
.screen figure {
	/* Nothing made sense, until I made it ALL CAPITALS */
	text-transform: uppercase;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.screen figure::after {
	/* This blinking underscore makes the user feel like a hacker */
	content: '_';
	animation: 2s blink step-end infinite;
}

@keyframes blink {
	from,
	to {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}
